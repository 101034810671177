import { Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import RouteHomeClass from './routes/Home/routes.home.class';
import RouteNavBarClass from './routes/NavBar/routes.navbar.class';
import RouteModelsClass from './routes/models/routes.models.class';
import Authentication from './routes/auth/routes.auth.class';
import { useEffect, useContext } from 'react';
import { UserContext } from './contexts/user.context';
import RouteModelClass from './routes/model/routes.model.class';
import RouteEditModelClass from './routes/editModel/routes.editmodel.class';
import RouteAdminClass from './routes/routes.admin.class/routes.admin.class';
import RoutesAdminCharactersClass from './routes/routes.admin.class/routes.admin.characters.class';
import RoutesAdminHomeClass from './routes/routes.admin.class/routes.admin.home.class';
import RouteSearchClass from './routes/search/routes.search.class';
import RouteIdeaClass from './routes/idea/routes.idea.class';
import RouteEditIdeaClass from './routes/editIdea/routes.editidea.class';
import RouteNewIdeaClass from './routes/newIdea/routes.newidea.class';

const App = () => {
  document.cookie = `referral_key=hello;max-age=7776000;domain=liltiffyrps.com;`
  const {currentUser, setDisplayName, setAN, setAP, setAU, setEm, mt, setMt, setRp} = useContext(UserContext);
  
  useEffect(()=>{
    //console.log(currentUser);
    let uid = -1;
    let response =[];
    if (currentUser!==null){
        uid = currentUser.uid;
    }
    //console.log(uid);
    
    fetch("https://api.liltiffyrps.com/userDomain/V1/getUserUa", {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
        }),
        body: `test=0&uid=${uid}`
    }
    )
        .then(response => response.json())
        .then(response => {
          //console.log(response)
          setDisplayName(response.body.displayName)
          setAN(response.body.aN)
          setAP(response.body.aP)
          setAU(response.body.aU)
          setEm(response.body.em)
          setMt(response.body.mt)
          setRp(response.body.rp.active)
          
        
        })


    

  }, [currentUser])
    



//console.log(mt);
  return (
    <div className='App'>
      <Routes>
        <Route path='/' element={<RouteNavBarClass />}>
          <Route index element={<RouteHomeClass />}>
          
          </Route>
          <Route path='search/:searchType' element={<RouteSearchClass />}>
            
          </Route>
          <Route path='/idea/:ideaId' element={<RouteIdeaClass />}>
        
          </Route>
        

          
          <Route path='/models/all' element={<RouteModelsClass />}>

          </Route>
          <Route path='/model/:modelId' element={<RouteModelClass />}>
        
          </Route>
          
          {currentUser===null ? (<Route path='auth' element={<Authentication />}></Route>):<Route path='auth' element={<Navigate to ="/" />}></Route>}
          <Route path='/admin' element={<RouteAdminClass />}>
            <Route index element={<RoutesAdminHomeClass />}></Route>
            <Route path='/admin/models/:stateText' element={<RoutesAdminCharactersClass />}> 
            
            </Route>
            <Route path='/admin/edit/model/:modelId' element={<RouteEditModelClass />} >

            </Route>
            <Route path='/admin/edit/idea/:ideaId' element={<RouteEditIdeaClass />} >

            </Route>

            <Route path='/admin/newIdea' element={<RouteNewIdeaClass />} >

            </Route>


          </Route>
          <Route path='*' element={<Navigate to="/" replace={true} />} />
        </Route>
      </Routes>
    </div>
  );

}

export default App;
