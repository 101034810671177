import { Fragment, useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { UserContext } from "../../contexts/user.context";
import PartsModelTitle from "../../parts/components/parts.components.model.title/parts.components.model.title";
import PartsModelGallery from "../../parts/components/parts.components.model.gallery/parts.components.model.gallery";
import Accordion from 'react-bootstrap/Accordion';
import SharedComponentCard from "../../shared/components/shared.components.card/shared.components.card.class";
import PartsModelGenreSelect from "../../parts/components/parts.components.model.genreselect/parts.compoenents.model.genreselect";
import PartsModelEditCharLink from "../../parts/components/parts.components.model.editcharLink/parts.components.model.editcharLink";
import PartsMinisButton from "../../parts/components/minis/parts.minis.button/parts.minis.button";
import PartsMinisFormInput from "../../parts/components/minis/parts.minis.formInput/parts.minis.formInput";
import Container from "react-bootstrap/Container";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

const defaultUnplannedFormFields = {
    unFirst: "",
    unNick: "",
    unLast: "",
    unGrade: 0,
    unPubertyS: 8,
    unPubertyE: 14,
}

const defaultCharacterFormFields = {

    cha1First: "",
    cha1Nick: "",
    cha1Last: "",
    cha1Grade: 0,
    cha1PubertyS: 8,
    cha1PubertyE: 14,
    cha1BirthMonth: 1,
    cha1BirthDay: 1,
    cha1Real: true,
    cha1Anime: false,
    cha2Ageless: false,
    cha2CardText: "",
    cha2Location: "",
    cha2Occupation: "",
    cha2Subtitle: "",
    cha2Race: "",
    cha2AltName: "",
    cha2Thumb: "",


}

const CreateFormGroupText = ({ id, value, title, helpText, isRequired, onChangeHandler }) => {
    return (
        <Form.Group className="mb-3" style={{ color: "black" }}>
            <Form.Label htmlFor={id}>{title}</Form.Label>
            <Form.Control
                type="text"
                id={id}
                name={id}
                aria-describedby={`${id}HelpBlock`}
                defaultValue={value}
                required={isRequired}
                onChange={onChangeHandler}
            />
            <Form.Text id={`${id}HelpBlock`} >{/*muted*/}
                {helpText}
            </Form.Text>
        </Form.Group>
    )
}

const CreateFormGroupArea = ({ id, value, title, helpText, isRequired, onChangeHandler, rows }) => {
    return (
        <Form.Group className="mb-3" style={{ color: "black" }}>
            <Form.Label htmlFor={id}>{title}</Form.Label>
            <Form.Control
                as="textarea"
                id={id}
                name={id}
                aria-describedby={`${id}HelpBlock`}
                defaultValue={value}
                required={isRequired}
                onChange={onChangeHandler}
                rows={rows}
            />
            <Form.Text id={`${id}HelpBlock`} >{/*muted*/}
                {helpText}
            </Form.Text>
        </Form.Group>
    )
}

const CreateFormGroupNumber = ({ id, value, title, helpText, isRequired, onChangeHandler }) => {
    return (
        <Form.Group className="mb-3" style={{ color: "black" }}>
            <Form.Label htmlFor={id}>{title}</Form.Label>
            <Form.Control
                type="number"
                id={id}
                name={id}
                aria-describedby={`${id}HelpBlock`}
                defaultValue={parseInt(value)}
                required={isRequired}
                onChange={onChangeHandler}
            />
            <Form.Text id={`${id}HelpBlock`} >{/*muted*/}
                {helpText}
            </Form.Text>
        </Form.Group>
    )
}


const RouteEditModelClass = () => {
    let { modelId } = useParams();

    const [character, setCharacter] = useState([]);
    const { mt } = useContext(UserContext);
    const [unplannedFormfields, setUnplannedFormFields] = useState(defaultUnplannedFormFields);
    const [characterFormfields, setCharacterFormFields] = useState(defaultCharacterFormFields);
    const { unFirst, unNick, unLast, unGrade, unPubertyS, unPubertyE } = unplannedFormfields;
    const { cha1First, cha1Nick, cha1Last, cha1Grade, cha1PubertyS, cha1PubertyE, cha1BirthMonth, cha1BirthDay, cha1Real, cha1Anime,
        cha2Ageless, cha2CardText, cha2Location, cha2Occupation, cha2Subtitle, cha2Race, cha2AltName, cha2Thumb, } = characterFormfields;

    //console.log(topCharacters);

    const { currentUser } = useContext(UserContext);
    const [genres, setGenres] = useState([]);
    const [pickedGenre, setPickedGenre] = useState([]);
    const [pickedCat, setPickedCat] = useState([]);
    const [cats, setCats] = useState([]);
    const [characterCats, setCharacterCats] = useState([]);


    useEffect(() => {
        let uid = -1;
        let displayName = "";
        if (currentUser !== null) {
            uid = currentUser.uid;
            displayName = currentUser.displayName 
        }
        //console.log(currentUser);

        fetch("https://api.liltiffyrps.com/modelDomain/V2/getEditModelSearch", {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: `title=meow&release=1&version=1&test=0&aid=${uid}&displayName=${displayName}&cid=${modelId}`
        }
        )
            .then(response => response.json())
            .then(users => {
                //console.log (users.body.name);
                switch (users.status_code) {

                    case 200:
                        setCharacter(users.body);
                        //console.log(users.body);
                        setCharacterFormFields(

                            {
                                cha1First: users.body[0].name.first,
                                cha1Nick: users.body[0].name.nick,
                                cha1Last: users.body[0].name.last,
                                cha1Grade: parseInt(users.body[0].grade),
                                cha1PubertyS: parseInt(users.body[0].pubSt),
                                cha1PubertyE: parseInt(users.body[0].pubEn),
                                cha1BirthMonth: parseInt(users.body[0].birthMonth),
                                cha1BirthDay: parseInt(users.body[0].birthDay),
                                cha1Real: parseInt(users.body[0].real),
                                cha1Anime: parseInt(users.body[0].anime),
                                cha2Ageless: parseInt(users.body[0].ageless),
                                cha2CardText: users.body[0].cardText,
                                cha2Location: users.body[0].location,
                                cha2Occupation: users.body[0].occupation,
                                cha2Subtitle: users.body[0].subtitle,
                                cha2Race: users.body[0].race.cha2,
                                cha2AltName: users.body[0].name.alt,
                                cha2Thumb: users.body[0].thumb,
                            }
                        );
                        break;
                    case 300:
                        //not found

                        break;

                    case 400:
                    //unauthorized

                }

            })



    }, [currentUser, modelId]);

    useEffect(() => {
        let uid = -1;
        if (currentUser !== null) {
            uid = currentUser.uid;
        }

        fetch("https://api.liltiffyrps.com/modelDomain/V2/getCharacterCats", {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: `title=meow&release=1&version=1&test=0&aid=${uid}&cid=${modelId}`
        }
        )
            .then(response => response.json())
            .then(users => {
                //console.log (users.body.name);
                switch (users.status_code) {

                    case 200:
                        setCharacterCats(users.body);
                        setPickedCat(23);
                        break;
                    case 300:
                        //not found

                        break;

                    case 400:
                    //unauthorized

                }

            })



    }, [currentUser, modelId]);

    useEffect(() => {
        let uid = -1;
        if (currentUser !== null) {
            uid = currentUser.uid;
        }

        fetch("https://api.liltiffyrps.com/modelDomain/V2/getCharacterCatsInfo", {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: `title=meow&release=1&version=1&test=0&aid=${uid}&cid=${modelId}`
        }
        )
            .then(response => response.json())
            .then(users => {
                //console.log (users.body.name);
                switch (users.status_code) {

                    case 200:
                        setCats(users.body);
                        break;
                    case 300:
                        //not found

                        break;

                    case 400:
                    //unauthorized

                }

            })



    }, [currentUser, modelId]);

    useEffect(() => {
        let uid = -1;
        if (currentUser !== null) {
            uid = currentUser.uid;
        }
        //console.log(`title=meow&release=1&version=1&test=0&aid=${uid}`);
        fetch("https://api.liltiffyrps.com/modelDomain/V2/getGenreInfo", {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: `title=meow&release=1&version=1&test=0&aid=${uid}`
        }
        )
            .then(response => response.json())
            .then(users => {
                setGenres(users.body);
                setPickedGenre(1);
            })


    }, [currentUser]);

    const handleUnplannedChange = (event) => {
        const { name, value } = event.target;
        //console.log ("Name: ",name," Value: ",value)
        setUnplannedFormFields({ ...unplannedFormfields, [name]: value });
        //console.log(unplannedFormfields);
    };

    const handleCharacterChange = (event) => {
        const { name, value } = event.target;
        setCharacterFormFields({ ...characterFormfields, [name]: value });
        console.log(characterFormfields);
    };

    const handleNewGenreChange = (event) => {
        const { value } = event.target;
        setPickedGenre(value);
        console.log(pickedGenre);
    };

    const handleNewCatChange = (event) => {
        const { value } = event.target;
        setPickedCat(value);
        console.log(pickedGenre);
    };

    const resetFormFields = () => {
        setUnplannedFormFields(defaultUnplannedFormFields);
        setCharacterFormFields(defaultCharacterFormFields);
    }

    const handleUnPlannedSubmit = async (event) => {
        event.preventDefault();

        try {
            let uid = -1;
            if (currentUser !== null) {
                uid = currentUser.uid;
            }

            fetch("https://api.liltiffyrps.com/modelDomain/V2/setModelToPipeline", {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded',
                }),
                body: `title=meow&release=1&version=1&test=0&aid=${uid}&cid=${modelId}&first=${unFirst}&nick=${unNick}&last=${unLast}&age=${unGrade}&pubertyS=${unPubertyS}&pubertyE=${unPubertyE}`

            }
            )
                .then(response => response.json())
                .then(users => {
                    //console.log (users.status_code)
                    switch (users.status_code) {
                        case 200:
                            window.location.reload();
                            break;
                        case 300:
                            //not found

                            break;

                        case 400:
                        //unauthorized

                    }

                })
            //const {user} = await signInAuthUserWithEmailandPassword(email, password);
            resetFormFields();
        } catch (error) {
            switch (error.code) {
                case 'auth/wrong-password':
                    alert('Incorrect Password for Email');
                    break;
                case 'auth/user-not-found':
                    alert('No user associated with this email');
                    break;
                default:
                    console.log(error);
            }

        }
    };

    const handleNewGenreSubmit = async (event) => {
        event.preventDefault();
        
        try {
            let uid = -1;
            if (currentUser !== null) {
                uid = currentUser.uid;
            }

            fetch("https://api.liltiffyrps.com/modelDomain/V2/setNewGenreModel", {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded',
                }),
                body: `title=meow&release=1&version=1&test=0&aid=${uid}&cid=${modelId}&gid=${pickedGenre}`

            }
            )
                .then(response => response.json())
                .then(users => {
                    //console.log (users.status_code)
                    switch (users.status_code) {
                        case 200:
                            window.location.href = `/admin/edit/model/${users.body.cid}`;
                            //window.location.reload();
                            break;
                        case 300:
                            //not found

                            break;

                        case 400:
                        //unauthorized

                    }

                })
            //const {user} = await signInAuthUserWithEmailandPassword(email, password);
            resetFormFields();
        } catch (error) {
            switch (error.code) {
                case 'auth/wrong-password':
                    alert('Incorrect Password for Email');
                    break;
                case 'auth/user-not-found':
                    alert('No user associated with this email');
                    break;
                default:
                    console.log(error);
            }

        }
    };

    const handleNewCatSubmit = async (event) => {
        event.preventDefault();
        
        try {
            let uid = -1;
            if (currentUser !== null) {
                uid = currentUser.uid;
            }

            fetch("https://api.liltiffyrps.com/modelDomain/V2/setNewCatForCharacter", {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded',
                }),
                body: `title=meow&release=1&version=1&test=0&aid=${uid}&cid=${modelId}&catid=${pickedCat}`

            }
            )
                .then(response => response.json())
                .then(users => {
                    //console.log (users.status_code)
                    switch (users.status_code) {
                        case 200:
                            window.location.reload();
                            break;
                        case 300:
                            //not found

                            break;

                        case 400:
                        //unauthorized

                    }

                })
            //const {user} = await signInAuthUserWithEmailandPassword(email, password);
            resetFormFields();
        } catch (error) {
            switch (error.code) {
                case 'auth/wrong-password':
                    alert('Incorrect Password for Email');
                    break;
                case 'auth/user-not-found':
                    alert('No user associated with this email');
                    break;
                default:
                    console.log(error);
            }

        }
    };

    const handleEditCharacterSubmit = async (event) => {
        event.preventDefault();
        console.log(characterFormfields);

        try {
            let uid = -1;
            if (currentUser !== null) {
                uid = currentUser.uid;
            }
            //const serviceBody = `title=meow&release=1&version=1&test=0&aid=${uid}&cid=${modelId}&cha1First=${cha1First}&cha1Nick=${cha1Nick}&cha1Last=${cha1Last}&cha1Grade=${cha1Grade}&cha1PubertyS=${cha1PubertyS}&cha1PubertyE=${cha1PubertyE}&cha1BirthMonth=${cha1BirthMonth}&cha1BirthDay=${cha1BirthDay}&cha1Real=${cha1Real}&cha1Anime=${cha1Anime}&cha2Ageless=${cha2Ageless}&cha2AltName=${cha2AltName}&cha2CardText=${cha2CardText}&cha2Location=${cha2Location}&cha2Occupation=${cha2Occupation}&cha2Race=${cha2Race}&cha2Subtitle=${cha2Subtitle}&cha2Thumb=${cha2Thumb}`
            //console.log(serviceBody);
            fetch("https://api.liltiffyrps.com/modelDomain/V2/setUpdateModel", {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded',
                }),
                body: `title=meow&release=1&version=1&test=0&aid=${uid}&cid=${modelId}&cha1First=${cha1First}&cha1Nick=${cha1Nick}&cha1Last=${cha1Last}&cha1Grade=${cha1Grade}&cha1PubertyS=${cha1PubertyS}&cha1PubertyE=${cha1PubertyE}&cha1BirthMonth=${cha1BirthMonth}&cha1BirthDay=${cha1BirthDay}&cha1Real=${cha1Real}&cha1Anime=${cha1Anime}&cha2Ageless=${cha2Ageless}&cha2AltName=${cha2AltName}&cha2CardText=${cha2CardText}&cha2Location=${cha2Location}&cha2Occupation=${cha2Occupation}&cha2Race=${cha2Race}&cha2Subtitle=${cha2Subtitle}&cha2Thumb=${cha2Thumb}`


            }
            )
                .then(response => response.json())
                .then(users => {
                    //console.log (users.status_code)
                    switch (users.status_code) {
                        case 200:
                            window.location.reload();
                            break;
                        case 300:
                            //not found

                            break;

                        case 400:
                        //unauthorized

                    }

                })
            //const {user} = await signInAuthUserWithEmailandPassword(email, password);
            //resetFormFields();

        } catch (error) {
            switch (error.code) {
                case 'auth/wrong-password':
                    alert('Incorrect Password for Email');
                    break;
                case 'auth/user-not-found':
                    alert('No user associated with this email');
                    break;
                default:
                    console.log(error);
            }

        }
    };



    //console.log(character);
    //console.log(characterFormfields);
    //console.log(cats);
    //console.log(characterCats);
    return (
        <Fragment>
            {character?.map((thisCharacter) => {
                return (
                    <div className="container" key={thisCharacter.keys.cid}>
                        {/* Gallery + details*/}
                        <div className="bg-primary shadow-lg rounded-3 px-4 py-3 mb-5">
                            <div className="px-lg-3">
                                <h2 className="text-light text-center">Editing {thisCharacter.name.longName} - <i className={thisCharacter.genre.icon}></i> {thisCharacter.genre.name}</h2>
                                <p className="bg-danger text-light text-center">Please note that while v8 does introduce new fields, many of these fields are used across versions.  Changes made to these characters may impact all site versions and data is not recoverable.</p>
                                <p className="bg-warning text-dark text-center">Clicking "Update Character" under any of the below forms will update the character based off the data in all forms.  They do not need to be submitted seperatly.  The repeating buttons are only there for convience when making minor updates.</p>

                            </div>
                        </div>
                        <div className="shadow-lg rounded-3 px-4 py-3 mb-5">

                            <img src={thisCharacter.pictures.thumbnail}></img><br />
                            <Link to={thisCharacter.viewUrl}>View Character</Link>
                        </div>


                        <div className="bg-secondary shadow-lg rounded-3 px-4 py-3 mb-5">
                            <div className="px-lg-3">
                                < div className="row">
                                    <div>
                                        <Container>
                                            <h3 className="text-center">Readonly Character Stats</h3>
                                            <p className="bg-success text-light text-center">This is helpful information about the character that cannot be updated.</p>
                                            <Form onSubmit={handleEditCharacterSubmit}>
                                                <Form.Group className="mb-3" style={{ color: "black" }}>
                                                    <Form.Label column sm="2">
                                                        Total Views
                                                    </Form.Label>
                                                    <Form.Control type="number" placeholder={thisCharacter.views.total} readOnly />
                                                    <Form.Text id={`viewsHelpBlock`} >{/*muted*/}
                                                        The total number of times this character/genre was viewed on v8.  Note the logic to reset each day, week, month, and year is not implemented yet.
                                                    </Form.Text>
                                                </Form.Group>
                                                <Form.Group className="mb-3" style={{ color: "black" }}>
                                                    <Form.Label column sm="2">
                                                        Year's Views
                                                    </Form.Label>
                                                    <Form.Control type="number" placeholder={thisCharacter.views.total} readOnly />
                                                    <Form.Text id={`viewsYearlyHelpBlock`} >{/*muted*/}
                                                        The number of times this year this character/genre was viewed on v8.  Note the logic to reset each day, week, month, and year is not implemented yet.
                                                    </Form.Text>
                                                </Form.Group>
                                                <Form.Group className="mb-3" style={{ color: "black" }}>
                                                    <Form.Label column sm="2">
                                                        Month's Views
                                                    </Form.Label>
                                                    <Form.Control type="number" placeholder={thisCharacter.views.total} readOnly />
                                                    <Form.Text id={`viewsMonthlyHelpBlock`} >{/*muted*/}
                                                        The number of times this month this character/genre was viewed on v8.  This is the value that the trending characters is sorted by.  Note the logic to reset each day, week, month, and year is not implemented yet.
                                                    </Form.Text>
                                                </Form.Group>
                                                <Form.Group className="mb-3" style={{ color: "black" }}>
                                                    <Form.Label column sm="2">
                                                        Week's Views
                                                    </Form.Label>
                                                    <Form.Control type="number" placeholder={thisCharacter.views.total} readOnly />
                                                    <Form.Text id={`viewsWeeklyHelpBlock`} >{/*muted*/}
                                                        The number of times this week this character/genre was viewed on v8.  Note the logic to reset each day, week, month, and year is not implemented yet.
                                                    </Form.Text>
                                                </Form.Group>
                                                <Form.Group className="mb-3" style={{ color: "black" }}>
                                                    <Form.Label column sm="2">
                                                        Day's Views
                                                    </Form.Label>
                                                    <Form.Control type="number" placeholder={thisCharacter.views.total} readOnly />
                                                    <Form.Text id={`viewsDailyHelpBlock`} >{/*muted*/}
                                                        The number of times today this character/genre was viewed on v8.  Note the logic to reset each day, week, month, and year is not implemented yet.
                                                    </Form.Text>
                                                </Form.Group>
                                                <Form.Group className="mb-3" style={{ color: "black" }}>
                                                    <Form.Label column sm="2">
                                                        Date Added
                                                    </Form.Label>
                                                    <Form.Control type="number" placeholder={thisCharacter.dateAdded} readOnly />
                                                    <Form.Text id={`dateAddedHelpBlock`} >{/*muted*/}
                                                        The date this character was published in v8. 1970-12-31 is the default unplublished value.
                                                    </Form.Text>
                                                </Form.Group>
                                                <Form.Group className="mb-3" style={{ color: "black" }}>
                                                    <Form.Label column sm="2">
                                                        Date Last Updated.
                                                    </Form.Label>
                                                    <Form.Control type="number" placeholder={thisCharacter.dateUpdated} readOnly />
                                                    <Form.Text id={`dateUpdatedHelpBlock`} >{/*muted*/}
                                                        The date this character was last updated in v8.  Submitting the below form will change this value to today. 1970-12-31 is the default unplublished value.
                                                    </Form.Text>
                                                </Form.Group>
                                                <Form.Group className="mb-3" style={{ color: "black" }}>
                                                    <Form.Label column sm="2">
                                                        Pipeline Value
                                                    </Form.Label>
                                                    <Form.Control type="number" placeholder={thisCharacter.pipeline} readOnly />
                                                    <Form.Text id={`pipelineHelpBlock`} >{/*muted*/}
                                                        Automatically updated when the needed step is preformed.  a=archived, w = unreleased unnamed, u=unnamed, s=pipeline, n=published/ready for publishing
                                                    </Form.Text>
                                                </Form.Group>
                                                <Form.Group className="mb-3" style={{ color: "black" }}>
                                                    <Form.Label column sm="2">
                                                        Stage Value
                                                    </Form.Label>
                                                    <Form.Control type="number" placeholder={thisCharacter.stage} readOnly />
                                                    <Form.Text id={`stageHelpBlock`} >{/*muted*/}
                                                        Automatically updated when the needed step is preformed.  0= archived, 1 = unnamed , 2 = in pipeline, 3 = will publish next release, 4 = Published
                                                    </Form.Text>
                                                </Form.Group>
                                                <Form.Group className="mb-3" style={{ color: "black" }}>
                                                    <Form.Label column sm="2">
                                                        Release
                                                    </Form.Label>
                                                    <Form.Control type="number" placeholder={thisCharacter.release} readOnly />
                                                    <Form.Text id={`releaseHelpBlock`} >{/*muted*/}
                                                        The Release the character was published.  1 is published before the first release.  The first release starts with 2.
                                                    </Form.Text>
                                                </Form.Group>
                                                <Form.Group className="mb-3" style={{ color: "black" }}>
                                                    <Form.Label column sm="2">
                                                        Version
                                                    </Form.Label>
                                                    <Form.Control type="number" placeholder={thisCharacter.version} readOnly />
                                                    <Form.Text id={`versionHelpBlock`} >{/*muted*/}
                                                        The version this character is updated for.  Editing the character will update them to the latest version.
                                                    </Form.Text>
                                                </Form.Group>
                                            </Form>
                                        </Container>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {(mt == 'a' || mt == 'm') & thisCharacter.status.state === "unplanned" ?
                            <div className="bg-warning shadow-lg rounded-3 px-4 py-3 mb-5">
                                <div className="px-lg-3">
                                    <Container>
                                        <h3 className="text-center">Move Unplanned Character To Pipeline</h3>
                                        <p>Once the character has been moved to the pipeline they will be available to editing.</p>

                                        <Form onSubmit={handleUnPlannedSubmit}>
                                            <CreateFormGroupText
                                                id="unFirst"
                                                isRequired={true}
                                                onChangeHandler={handleUnplannedChange}
                                                value={unFirst}
                                                title="First Name"
                                                helpText="Please enter the full first name of the character." >
                                            </CreateFormGroupText>
                                            <CreateFormGroupText
                                                id="unNick"
                                                isRequired={false}
                                                onChangeHandler={handleUnplannedChange}
                                                value={unNick}
                                                title="Nick Name"
                                                helpText="Please enter the nick name of the character.  If none, leave blank." >
                                            </CreateFormGroupText>
                                            <CreateFormGroupText
                                                id="unLast"
                                                isRequired={true}
                                                onChangeHandler={handleUnplannedChange}
                                                value={unLast}
                                                title="Last Name"
                                                helpText="Please enter the last name of the character.  This would be the actual last name, reguardless of the family name.  Note: family logic is not implemented yet in v8." >
                                            </CreateFormGroupText>
                                            <CreateFormGroupNumber
                                                id="unGrade"
                                                isRequired={true}
                                                onChangeHandler={handleUnplannedChange}
                                                value={parseInt(unGrade)}
                                                title="Grade"
                                                helpText="Please enter the grade of the character.  This will be used to factor the age." >
                                            </CreateFormGroupNumber>
                                            <CreateFormGroupNumber
                                                id="unPubertyS"
                                                isRequired={true}
                                                onChangeHandler={handleUnplannedChange}
                                                value={parseInt(unPubertyS)}
                                                title="Puberty Start"
                                                helpText="Please enter how old the character is when the start puberty.  This is used to help determine character stats at various ages." >
                                            </CreateFormGroupNumber>
                                            <CreateFormGroupNumber
                                                id="unPubertyE"
                                                isRequired={true}
                                                onChangeHandler={handleUnplannedChange}
                                                value={parseInt(unPubertyE)}
                                                title="Puberty End"
                                                helpText="Please enter how old the character is when the start ends.  This is used to help determine character stats at various ages." >
                                            </CreateFormGroupNumber>

                                            <Button variant="primary" type="submit">
                                                Move to Pipeline
                                            </Button>
                                        </Form>
                                    </Container>
                                </div>
                            </div>
                            :
                            <Fragment>
                                <div className="bg-secondary shadow-lg rounded-3 px-4 py-3 mb-5">
                                    <Container>
                                        <h3 className="text-center">Create New Genre for Character</h3>
                                        <p className="bg-warning text-light text-center">Please check to ensure there isn't already a genre version in published, next release or the pipeline.</p>
                                        <Form onSubmit={handleNewGenreSubmit}>
                                            <Form.Group className="mb-3" style={{ color: "black" }}>
                                                <Form.Select onChange={handleNewGenreChange} aria-label="Default select example">
                                                    {genres?.map((genre) => {
                                                        return (
                                                            <Fragment key={genre.id}>
                                                                <option value={genre.id}>{genre.name}</option>
                                                            </Fragment>
                                                        )
                                                    })}
                                                </Form.Select>
                                            </Form.Group>

                                            <Button variant="primary" type="submit">
                                                Create New Genre Form of Character
                                            </Button>
                                        </Form>

                                    </Container>

                                </div>
                                <div className="bg-secondary shadow-lg rounded-3 px-4 py-3 mb-5">
                                    <Container>
                                        <h3 className="text-center">Add Character to a Catagory</h3>
                                        <p className=" text-light text-center">These are the groups that users are granted to view characters.</p>
                                        <p><b>Current Catagories</b>:</p>
                                        {characterCats?.map((characterCat) => {
                                                        return (
                                                            <Fragment key={characterCat.id}>
                                                                <p>{characterCat.name}</p>
                                                            </Fragment>
                                                        )
                                                    })}
                                                    
                                        <Form onSubmit={handleNewCatSubmit}>
                                            <Form.Group className="mb-3" style={{ color: "black" }}>
                                                <Form.Select onChange={handleNewCatChange} aria-label="Default select example">
                                                    {cats?.map((cat) => {
                                                        return (
                                                            <Fragment key={cat.id}>
                                                                <option value={cat.id}>{cat.name}</option>
                                                            </Fragment>
                                                        )
                                                    })}
                                                </Form.Select>
                                            </Form.Group>

                                            <Button variant="primary" type="submit">
                                                Add Character to Catagory
                                            </Button>
                                        </Form>

                                    </Container>

                                </div>
                                <div className="bg-info shadow-lg rounded-3 px-4 py-3 mb-5">
                                    <div className="px-lg-3">
                                        < div className="row">
                                            <div>
                                                <Container>
                                                    <h3 className="text-center">CHA1: General Character Information</h3>
                                                    <p className="bg-danger text-light text-center">All fields in this section apply to characters across their various genre forms.</p>
                                                    <Form onSubmit={handleEditCharacterSubmit}>
                                                        <Form.Group className="mb-3" style={{ color: "black" }}>
                                                            <Form.Label column sm="2">
                                                                MiD
                                                            </Form.Label>
                                                            <Form.Control type="number" placeholder={thisCharacter.keys.mid} readOnly />
                                                            <Form.Text id={`MiDHelpBlock`} >{/*muted*/}
                                                                This is the legacy charcter key and the primary key to CHA1.  Thus it cannot be changed.
                                                            </Form.Text>
                                                        </Form.Group>
                                                        <CreateFormGroupText
                                                            id="cha1First"
                                                            isRequired={true}
                                                            onChangeHandler={handleCharacterChange}
                                                            value={cha1First}
                                                            title="First Name"
                                                            helpText="Please enter the full first name of the character." >
                                                        </CreateFormGroupText>
                                                        <CreateFormGroupText
                                                            id="cha1Nick"
                                                            isRequired={false}
                                                            onChangeHandler={handleCharacterChange}
                                                            value={cha1Nick}
                                                            title="Nick Name"
                                                            helpText="Please enter the nick name of the character.  If none, leave blank." >
                                                        </CreateFormGroupText>
                                                        <CreateFormGroupText
                                                            id="cha1Last"
                                                            isRequired={true}
                                                            onChangeHandler={handleCharacterChange}
                                                            value={cha1Last}
                                                            title="Last Name"
                                                            helpText="Please enter the last name of the character.  This would be the actual last name, reguardless of the family name.  Note: family logic is not implemented yet in v8." >
                                                        </CreateFormGroupText>
                                                        <CreateFormGroupNumber
                                                            id="cha1Grade"
                                                            isRequired={true}
                                                            onChangeHandler={handleCharacterChange}
                                                            value={parseInt(cha1Grade)}
                                                            title="Grade"
                                                            helpText="Please enter the grade of the character.  This will be used to factor the age." >
                                                        </CreateFormGroupNumber>
                                                        <CreateFormGroupNumber
                                                            id="cha1BirthMonth"
                                                            isRequired={true}
                                                            onChangeHandler={handleCharacterChange}
                                                            value={parseInt(cha1BirthMonth)}
                                                            title="Birth Month"
                                                            helpText="Please enter how month the character was born in." >
                                                        </CreateFormGroupNumber>
                                                        <CreateFormGroupNumber
                                                            id="cha1BirthDay"
                                                            isRequired={true}
                                                            onChangeHandler={handleCharacterChange}
                                                            value={parseInt(cha1BirthDay)}
                                                            title="Birth Day"
                                                            helpText="Please enter how day of the month the character was born in." >
                                                        </CreateFormGroupNumber>
                                                        <CreateFormGroupNumber
                                                            id="cha1PubertyS"
                                                            isRequired={true}
                                                            onChangeHandler={handleCharacterChange}
                                                            value={parseInt(cha1PubertyS)}
                                                            title="Puberty Start"
                                                            helpText="Please enter how old the character is when the start puberty.  This is used to help determine character stats at various ages." >
                                                        </CreateFormGroupNumber>
                                                        <CreateFormGroupNumber
                                                            id="cha1PubertyE"
                                                            isRequired={true}
                                                            onChangeHandler={handleCharacterChange}
                                                            value={parseInt(cha1PubertyE)}
                                                            title="Puberty End"
                                                            helpText="Please enter how old the character is when the start ends.  This is used to help determine character stats at various ages." >
                                                        </CreateFormGroupNumber>
                                                        <CreateFormGroupNumber
                                                            id="cha1Real"
                                                            isRequired={true}
                                                            onChangeHandler={handleCharacterChange}
                                                            value={parseInt(cha1Real)}
                                                            title="Has Photographes"
                                                            helpText="Please enter 0 if the charcter does not have any real photographes.  Enter 1 if they do." >
                                                        </CreateFormGroupNumber>
                                                        <CreateFormGroupNumber
                                                            id="cha1Anime"
                                                            isRequired={true}
                                                            onChangeHandler={handleCharacterChange}
                                                            value={parseInt(cha1Anime)}
                                                            title="Has Drawn Pictures"
                                                            helpText="Please enter 0 if the charcter does not have any drawn pictures.  Enter 1 if they do." >
                                                        </CreateFormGroupNumber>

                                                        <Button variant="primary" type="submit">
                                                            Update Character
                                                        </Button>
                                                    </Form>

                                                </Container>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="bg-info shadow-lg rounded-3 px-4 py-3 mb-5">
                                    <div className="px-lg-3">
                                        < div className="row">
                                            <div>
                                                <Container>
                                                    <h3 className="text-center">CHA2: Genre Specific Character Information</h3>
                                                    <p className="bg-success text-light text-center">All fields in this section apply to the specific version of the character tied to the genre.</p>
                                                    <Form onSubmit={handleEditCharacterSubmit}>
                                                        <Form.Group className="mb-3" style={{ color: "black" }}>
                                                            <Form.Label column sm="2">
                                                                CiD
                                                            </Form.Label>
                                                            <Form.Control type="number" placeholder={thisCharacter.keys.cid} readOnly />
                                                            <Form.Text id={`CiDHelpBlock`} >{/*muted*/}
                                                                This is the charcter's genre form key and the primary key to CH2.  In version 8 it is the main key used to access a specific genre character.  It cannot be changed.
                                                            </Form.Text>
                                                        </Form.Group>
                                                        <CreateFormGroupText
                                                            id="cha2AltName"
                                                            isRequired={false}
                                                            onChangeHandler={handleCharacterChange}
                                                            value={cha2AltName}
                                                            title="Genre Specific Name Override"
                                                            helpText="Enter the name of the character here only if it differs from the base character's name.  If the base character's name is used for this genre please leave this field blank." >
                                                        </CreateFormGroupText>
                                                        <CreateFormGroupText
                                                            id="cha2Race"
                                                            isRequired={false}
                                                            onChangeHandler={handleCharacterChange}
                                                            value={cha2Race}
                                                            title="Race Override"
                                                            helpText="Enter the race of the character here only if it differs from the model's race.  If the model's race is used for this genre please leave this field blank." >
                                                        </CreateFormGroupText>
                                                        <CreateFormGroupText
                                                            id="cha2Subtitle"
                                                            isRequired={false}
                                                            onChangeHandler={handleCharacterChange}
                                                            value={cha2Subtitle}
                                                            title="Short Description"
                                                            helpText="Please enter the short description of the character.  This should be a phrase and appears on the character card" >
                                                        </CreateFormGroupText>
                                                        <CreateFormGroupArea
                                                            id="cha2CardText"
                                                            isRequired={false}
                                                            onChangeHandler={handleCharacterChange}
                                                            value={cha2CardText}
                                                            title="Long Description"
                                                            helpText="Please enter the long description of the character."
                                                            rows="4" >
                                                        </CreateFormGroupArea>
                                                        <CreateFormGroupNumber
                                                            id="cha2Ageless"
                                                            isRequired={true}
                                                            onChangeHandler={handleCharacterChange}
                                                            value={parseInt(cha2Ageless)}
                                                            title="Is Ageless"
                                                            helpText="Please enter 0 if the charcter ages normally and 1 if the character does not age, such as robots and goddesses." >
                                                        </CreateFormGroupNumber>
                                                        <CreateFormGroupText
                                                            id="cha2Location"
                                                            isRequired={true}
                                                            onChangeHandler={handleCharacterChange}
                                                            value={cha2Location}
                                                            title="Location"
                                                            helpText="Enter the place in which the character lives" >
                                                        </CreateFormGroupText>
                                                        <CreateFormGroupText
                                                            id="cha2Occupation"
                                                            isRequired={true}
                                                            onChangeHandler={handleCharacterChange}
                                                            value={cha2Occupation}
                                                            title="Occupation"
                                                            helpText="Enter the occupation or fantasy class of the character." >
                                                        </CreateFormGroupText>
                                                        <CreateFormGroupText
                                                            id="cha2Thumb"
                                                            isRequired={false}
                                                            onChangeHandler={handleCharacterChange}
                                                            value={cha2Thumb}
                                                            title="Thumbnail Override"
                                                            helpText="Typically the card thumbnail of the character will be the first picture in the gallery.  Enter a thumbnail picture file name to override that with the one provided for this genre character specifically." >
                                                        </CreateFormGroupText>
                                                        <Button variant="primary" type="submit">
                                                            Update Character
                                                        </Button>
                                                    </Form>
                                                </Container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>

                        }

                    </div >
                )
            })}





        </Fragment >
    )

}

export default RouteEditModelClass;