import { Fragment } from "react";
import { Link } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';

function PartsAdminSidebar() {

  return (
    <Fragment>
      {/* Sidebar*/}
      <aside className="col-lg-4">
        {/* Sidebar*/}
        <div className="offcanvas offcanvas-collapse bg-white w-100 rounded-3 shadow-lg py-1" id="shop-sidebar" style={{ maxWidth: 22 + 'rem' }}>
          <Accordion defaultActiveKey={["0"]} alwaysOpen>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Characters</Accordion.Header>
              <Accordion.Body>
                <div>
                  <Link to='/admin/models/charv8'>All v8 Characters</Link><br />
                  <Link to='/admin/models/charPublished'>Published Characters</Link><br />
                  <Link to='/admin/models/charNext'>Next Characters</Link><br />
                  <Link to='/admin/models/charPipeline'>Pipeline Characters</Link><br />
                  <Link to='/admin/models/charUnplanned'>Unplanned Characters</Link><br />
                  <Link to='/admin/models/charUnplannedNext'>Next Release Unplanned Characters</Link><br />
                  <Link to='/admin/models/charUnplannedArc'>Unreleased Unplanned Characters</Link><br />
                  <Link to='/admin/models/charArchived'>Archived Characters</Link><br />

                </div>

              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Story Ideas</Accordion.Header>
              <Accordion.Body>
                <div>

                  <Link to='/admin/newIdea'>New Story Idea</Link><br />
                  <Link to='/admin/models/ideav8'>All v8 Story Ideas</Link><br />
                  <Link to='/admin/models/ideaPublished'>Published Story Ideas</Link><br />
                  <Link to='/admin/models/ideaNext'>Next Story Ideas</Link><br />
                  <Link to='/admin/models/ideaPipeline'>Pipeline Story Ideas</Link><br />
                  {/*
                  <Link to='/admin/models/ideaUnplanned'>Unplanned Story Ideas</Link><br />
                  <Link to='/admin/models/ideaUnplannedNext'>Next Release Unplanned Story Ideas</Link><br />
                  <Link to='/admin/models/ideaUnplannedArc'>Unreleased Unplanned Story Ideas</Link><br />
                  */}
                  <Link to='/admin/models/ideaArchived'>Archived Story Ideas</Link><br />

                </div>

              </Accordion.Body>
            </Accordion.Item>

          </Accordion>



        </div>
      </aside>
    </Fragment>
  )
}

export default PartsAdminSidebar;